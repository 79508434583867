import React, { useEffect } from "react"
import { navigate } from "gatsby-link"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../utils/normalize.css"
import "../utils/css/screen.css"
import Title from "../components/title/subtitle"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function Contact() {
  useEffect(() => {
    gsap.fromTo(".page-head", { y: 20, opacity: 0 }, { y: 0, opacity: 1 })
    gsap.fromTo(
      ".post-content-body",
      { y: 20, opacity: 0 },
      { y: 0, opacity: 1, delay: 0.5 }
    )
  }, [])

  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  return (
    <Layout>
      <SEO
        title="お問い合わせ"
        description="しょうのまきへのお問い合わせはこちらから。東京でイラストデザイン制作やロゴデザイン、チラシ制作、フライヤー制作、パンフレットの制作、カード類の制作などの広告印刷物の制作などおこなっております。"
        keywords={[
          `イラスト制作`,
          `美容室の広告印刷物の制作`,
          `ロゴデザイン`,
          `チラシ制作`,
        ]}
      />

      <header className="page-head">
        <Title title="お問い合わせ" id="forms" />
      </header>

      <article className="post-content post-content-width">
        <div className="post-content-body">
          <form
            name="contact"
            method="post"
            action="/thanks/"
            data-netlify="true"
            onSubmit={handleSubmit}
          >
            <div className="row gtr-uniform">
              <div className="col-12 col-12-xsmall">
                <input
                  type="text"
                  name="お名前"
                  id="name"
                  placeholder="お名前"
                  onChange={handleChange}
                />
              </div>

              <div className="col-12 col-12-xsmall">
                <input
                  type="email"
                  name="メールアドレス"
                  id="email"
                  placeholder="メールアドレス"
                  onChange={handleChange}
                />
              </div>

              {/* Break */}
              <div className="col-12">
                <textarea
                  name="メッセージ内容"
                  id="message"
                  placeholder="メッセージ内容"
                  rows={6}
                  defaultValue={""}
                  onChange={handleChange}
                />
              </div>
              {/* Break */}

              {/* Break */}
              <div className="col-12">
                <ul className="actions">
                  <li>
                    <button
                      type="submit"
                      //defaultValue="Send Message"
                      className="primary"
                      // disabled={buttonDisabled}
                    >
                      送信
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </article>
    </Layout>
  )
}
